div.g-recaptcha {
  margin: 0 auto;
  width: 304px;
}

.carousel-custom
{
	height: 80vh;
	transition: 1s;
}
.carousel-custom .item
{
	height: 80vh;
	background-size: cover;
	background-position: center center;
}

.navbar-default .nav li a{
    color: #cbcbcb;
    font-size: 18px;
}

@media screen and (max-width: 1200px) {
	.navbar-default {
		background-color: transparent;
		border-color: transparent;
/*		padding: 15px 0;*/
	}

  .navbar-default .nav li a {
    color: #676a6c;
  }

  .navbar-collapse ul , .navbar-collapse{
    width: 100vw;
  }

/*  .navbar-header{
    margin-bottom:10px;
  }*/

	.navbar-wrapper .navbar {
		border-color: transparent;
	}

	.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
		background-color: transparent;
		border-color: transparent;
	}

	.navbar-default.navbar-clicked {
	  background-color: #fff;
	  border-color: #fff;
	}

  .navbar-default.navbar-clicked .home-logo {
/*    background: url('../icons/ico-logo.png');*/
    background-repeat: no-repeat;
  }

	.navbar-default .navbar-toggle {
		border-color: transparent;
	}

  div.vertical-center{
    min-height: 10vh;
  }

  div.carousel-text{
    top: 20vh;
    left: 15%;
    width: 70%;
    text-align: center;
  }

  div .carousel-trynow{
    font-size:25px;
    font-weight: bold;
    margin-top: 5vh;
  }

  #navbar-trynow, #navbar-trynow div, .navbar-register{
    width:0px !important;
    height:0px !important;
    padding:0px !important;
    margin:0px !important;
  }

}

.navbar.navbar-scroll .navbar-brand{
  padding: 15px 20px;
}

.navbar-register
{
	margin-top: 21px;
}


.navbar-default .nav li .navbar-register
{
    font-size: 15px;
    font-weight: bold;
}

.navbar-scroll.navbar-default .nav>li.active>a, .navbar-scroll.navbar-default .nav>li.active>a:focus, .navbar-default .nav>li.active>a
{
  color: #ffffff;
/*  font-weight: bold;*/
  border-bottom: 3px solid #aa9f6f !important;
}

.navbar-scroll.navbar-default .nav li a:hover {
    color: white;
}

.navbar-scroll > .container > .navbar-header > .navbar-brand > .home-logo{
/*  background: url('../icons/ico-logo.png');*/
  background-repeat: no-repeat;
  margin-top:6px;
}

.fsnx4ccimage
{
	position: absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;	
	z-index: -10;
}

@media screen and (max-width: 1200px) {

  .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
/*    margin-top: 7.5px;*/
  }
  .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
      display:block !important;
  }

  .upg-but-pos {
    text-align: left;
  }

  .upg-type-pos {
    margin-left: -5px;
  }

  .btn-submit.upg-btn, .btn-cancel.upg-btn{
    width: 35%;
  }

  #notif .modal-header{
    height: 65px;
  }

  div.camera_pag {
            position: relative;
            width: inherit;
            bottom: 33vh;
            text-align: center;
        }

}
/*Home logo*/
@media (min-width: 1024px) and (max-width: 1200px){
  img.logo-resize{
    width: 30vw;
  }
}
@media (min-width: 768px) and (max-width: 1023px){
  img.logo-resize{
    width: 45vw;
  }
}

@media (min-width: 436px) and (max-width: 767px){
  img.carousel-animation-resize {
    width: 40vw !important;
    height: auto !important;
    left: 30% !important;
  }
}

@media (min-width: 440px) and (max-width: 639px){
  img.image-resize2 {
    width: 70vw !important;
    height: auto;
  }

  .bubble1{
    left: -10% !important;
  }

  .bubble2{
    left: 16% !important;
  }
}

@media (min-width: 569px) and (max-width: 640px){
  .sm-image-1 {
    width: 30vw !important;
  }
}

@media (min-width: 415px) and (max-width: 767px){
  img.logo-resize{
    width: 50vw;
  }
}

@media (min-width: 376px) and (max-width: 439px){
  img.image-resize2 {
    width: 70vw !important;
    height: auto;
  }

  .bubble1{
    top: 65% !important;
    left: -8% !important;
  }

  .bubble2{
    top: 80% !important;
    left: 12% !important;
  }
}

@media (min-width: 320px) and (max-width: 414px){
  img.logo-resize{
    width: 60vw;
  }
}

.plus{
	position: absolute;
	top: 75%;
	left: 40%;
	right: 40%;
	width: 20%;
	margin: auto;
	z-index: 1;
	transition: 0.3s;
}

.plus .fade{
	opacity: 0;
}

.fusionex-caption-title{
	font-size: 40px;

}

.fusionex-caption > p{
	font-size: 24px;
}

.category{
	padding-bottom: 4%;
}

.portlet-content > .row{
	padding: 0.5%;
}


.fusionex-carousel-content-fade{
	display: none;
}

.fusionex-carousel-content{
	height: 20vh;
}

.fusionex-carousel-content-container{
	height: 100%;
}

.fusionex-carousel-content-title{
	height: 50%;
	color: white;
	font-size: 18pt;
	text-align: center;
	vertical-align: bottom;
	padding: 2vh;
  	line-height: 130%;
}

.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
  justify-content:  center;
}

.fusionex-carousel-content-description{
	height: 50%;
	color: black;
	text-align: center;
	vertical-align: middle;
	font-size: 13pt;
}

.carousel-text{
	position: absolute;
	top: 30vh;
	left: 10vw;
	width: 50%;
	color: white;
}

.fusionex-caption{
	position: absolute;
	top: 0;
	left: 0;
	transition: 0.5s;
}

.fusionex-caption-fade{
	opacity: 0;
}

.fusionex-custom-featured-height{
	height: 25vh;
}

.horizontal-accordion .article.active .aqua-overlay{
	background-color: rgba(0, 180, 148, 0.4);
}

.custom-carousel-container{
	height: 100vh;
}

.fusionex-carousel-hexagon{
	display:none;
}

.carousel-indicators{
  padding-right: 0;
}

.carousel-indicators > li{
	color: #00b494;
	border-color: #00b494; 
}

.carousel-indicators .active{
	background-color: #00b494;
}

.carousel-inner{
  background-color: black;
}



@media (min-width: 1200px)
{
	

  .home-left{
    position:absolute;
    left: 4vw;
  }

	.vertical-center {
	min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
	min-height: 100vh; /* These two lines are counted as one :-)       */
	display: flex;
	align-items: center;
	justify-content:  center;
	}
	
	.carousel-text{
		top: 30vh;
	}
	
	.carousel-custom
	{
		height: 100vh;
		transition: 1s;
	}
	
	.fusionex-carousel-hexagon{
		display: inline;
	}
	
	.carousel-custom .item
	{
		height: 100vh;
		background-size: cover;
		background-position: center center;
	}
	
	.fusionex-carousel-content{
		position: absolute;
		height: 310px;
		width: 370px;
		padding: 0px 60px;
		top: 30vh;
		right: 10vw;
		z-index: 10;
		transition: 0.5s;
		opacity: 0.9;
	}
	
	.fusionex-carousel-content-fade{
		transform: translateX(-150px);
		opacity: 0;
		display: inline;
	}

	
	.fusionex-carousel-content-container{
		height: 100%;
	}
	
	.fusionex-carousel-content-title{
		height: 150px;
		padding: 20px;
		min-height: 150px;
		vertical-align: middle;
		margin-bottom: 2%;
	}
	
	.fusionex-carousel-content-description{
		height: 49%;
		vertical-align: middle;
		padding: 20px;
	}
	
	.no-hexagon{
		width: 78vw;
	}
	
	.no-hexagon > .fusionex-caption-title, .no-hexagon > .fusionex-caption-description{
		width: 100%;
		text-align: center;
	}
}
 
 
.section-1{
	width: 100%;
}

@media (min-width: 900px){
	.section-1
	{
		margin: auto;
	}
}
 
#notif .modal-dialog{
 	background-color:#ffffff;
 	border-radius: 4px;
 	box-shadow:0px 0px 0px 10px rgba(0,180,148,.2);
  margin-left: auto;
  margin-right: auto;
 }

 #notif .modal-dialog .modal-header{
 	background-color:#00b494;
 	color: #ffffff; 	padding-left:38px;
 	font-family: 'open sans';
 	font-weight: bold;
 	font-size: 28px;
 }

 #notif .modal-dialog .modal-header .close{
 	font-family: 'Open Sans';
 	font-weight: bold;
 	font-size: 28px;
 }

 #register-btn1{
  	background-image: url('../landing/images/register_btn1.png');
  	background-position: 20px 22px;
  	background-repeat: no-repeat;
  	padding-left:55px;
  	margin-right: 40px;
}

#register-btn2{
  	background-image: url('../landing/images/register_btn2.png');
  	background-position: 20px 20px;
  	background-repeat: no-repeat;
  	padding-left:55px;
}

#register-btn3{
	background-image: url('../landing/images/register_btn3.png');
	background-position: 20px 20px;
	background-repeat: no-repeat;
	padding-left:55px;
	margin-right: 40px;
}

#register-btn4{
  	background-image: url('../landing/images/register_btn4.png');
  	background-position: 20px 20px;
  	background-repeat: no-repeat;
  	padding-left:55px;
}

.dropdown{
	-webkit-appearance: none;       
	-moz-appearance: none;    
	appearance: none;
	border: 0px;
	outline: 0px;
	height: 100%;
	width: 100%;
	background: url('../landing/images/register_btn_dropdown.png') no-repeat right;
}

.register-button{
    background-color: rgba(30,30,30,0.2);
    /*color: #1b9a83;*/
    color:#00b494;
    border: 1px solid;
    width: 220px;
    /*height: 50px;*/
    height:43px;
    font-weight: bold;
    font-size: 16px;
    border-radius: 5px;
}
/*.register-button:hover{
    background-color: #056d62;
    color: #fff;  
    border:none;  
}*/

.contact-section {
  background-image: url('../images/img-getintouch-background-image.png');
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
/*  height: 700px;*/
}

.contact-field{
/*  background-color: rgba(98,98,98,0.5);*/
  border:none;
  color: #a3a3a3;
}


p.overview-desc{
   font-size:16px;
   color:#717171;
   font-style:'Open Sans';
   padding: 0 100px;
   margin-top:2%;
   line-height:168%;
}

.contact-field:focus{
/*  background-color: rgba(160,160,160,0.5);*/
  border:none;
  color:#a3a3a3;
}

/* .contact-field select#register-btn5.dropdown:focus {
    color:#ffffff;
 }

 .contact-field select#register-btn5.dropdown:focus option {
    color:#000000;
 }
*/
input[type="text"],input[type="email"]{
  font-size: 14px;
}

input[type="password"]:focus, input[type="text"].contact-field:focus, input[type="email"].contact-field:focus {

/*  background-color: rgba(160,160,160,0.5);*/
  border:none;
/*  color:#ffffff;*/
/*  opacity:1;*/
  box-shadow: none;
  }

input[type="password"].contact-field, input[type="text"].contact-field{
  color: #9a9a9a;
  /*background-color: #e3e3e3;
  border: 1px solid #18ab90;*/
  height: 40px;
  border-radius: 3px;
  border:none;
}

.contact-field{
  height: 40px;
  background-color: #ffffff;
}


.contact-field select::-webkit-input-placeholder{
  color:#ffffff;
  background-color: rgba(198,198,198,0.5);
}


input[type="text"].contact-field::-webkit-input-placeholder {
/*color: #1b9a83 !important;*/
color: #a3a3a3;
}
 
input[type="text"].contact-field:-moz-placeholder { /* Firefox 18- */
/*color: #1b9a83 !important;*/
color: #a3a3a3;
}
 
input[type="text"].contact-field::-moz-placeholder {  /* Firefox 19+ */
/*color: #1b9a83 !important;*/
color: #a3a3a3; 
}
 
input[type="text"].contact-field:-ms-input-placeholder {  
/*color: #1b9a83 !important;*/
color: #a3a3a3;
}

.registrationRow{
  margin-left: 20px;
  margin-right: 20px;
}

section p.register {
  color: #ffffff;
  font-size: 16px;
}

.custom-logo{
	width: 272px;
	height: 53px;
	background-image: url('/assets/icons/white-logo.png');
}

.navbar-default .navbar-brand,
.navbar-default .navbar-brand:hover{
	background: rgba(0,0,0,0);
}


body{
	font-family: 'Open Sans', Sans-serif;
  color: #000000;
  font-size:18px;

}

.section-2 p{
  width: 80%;
  margin: 0 auto;
}

.featured{
  background-image: url('../icons/overview_bg_pattern.jpg');
 
}

.testimonials{
  /*background-image: url('../icons/testimonials_bg_pattern.jpg');*/
  background-color:#d9d9d9;

}

.testimonials a:hover{
  text-decoration: none;
}

.testimonials span.navy:hover{
  color: #146f65;
}


hr{
	border-color: #d1d0d0;
	margin-top: 15px;
	margin-bottom: 0;
}

.copyright{
  color: #a3a3a3;
}

p.copyright{
	font-size: 13px;
}

p.copyright a{
	color: #8b8b8b;
}

a:hover.copyright-hover{
  text-decoration: none;
}

p.subtitle{
	font-size: 16px;
	line-height: 25px;
  width: 80vw;
  margin: 0 auto;
}

p.description{
	font-size: 16px;
}

p.description a{
/*	font-family: Serif;*/
	font-weight: 600;
	font-style: italic;
	color: #00b494;
}
.social-icon{
	margin-bottom: 0;
}
.social-icon a{
	background-color: gray;
	height: 30px;
	width: 30px;
}
/* nanv-line */
.navy-line{
	margin-top: 30px;
	margin-bottom: 40px;
  border-bottom: 2px solid #003340;
  width: 120px;
}

.navy-line-contact{
  border-bottom-color: #ffffff; 
  margin-bottom: 35px;
}

.carousel-inner.fusionex-custom-carousel-3{
    width: 100%;
    height:100%;
}

.fusionex-carousel-3{
    height:100vh;
    width:100vw;
}

.custom3-item{
    width: 60%;
    height: 100%;
    z-index: -1;
    background-size: cover;
    position: absolute;
    top: 0;
    opacity: 1;
    transition: 0.5s;
    transition-timing-function: ease-in-out;
}



@media (min-width: 1200px){
  .navbar-default .navbar-collapse{
    position: absolute;
    top: 0;
    right: 4vw;
  }

  #notif .modal-dialog{
    top: 20vh;
  }
  #notif .modal-dialog .modal-header{
    height:80px;
  }

  .navbar-scroll > .container > .navbar-header > .navbar-brand > .home-logo {
    margin-top: 0;
  }

}


.btn-submit{
    background-color: #00b494;
    color:#ffffff;
    width:280px;
    height:60px;
    border-radius:4px;
    border: 0;
}

.btn-cancel{
    background-color: #898a8a;
    color:#ffffff;
    width:280px;
    height:60px;
    border-radius:4px;
    border: 0;

}

.blacky-gradient
{
  width:100%;
  height: 160px;
  position:absolute;
  top: 0px;
  background: linear-gradient(rgba(0,0,0,1),rgba(0,0,0,0));
  z-index:1;
  opacity: 0.7;
}

@media(min-width:1200px){
  .regular-screen{
    margin-left:-135px!important;
  }  
}

.navbar{
  min-height: 100px;
  margin-bottom: 0;
}

/*using*/
/*.navbar.navbar-scroll .navbar-brand{
  margin-top: 0px;
}*/
  .navbar .navbar-brand{
    padding: 15px;
    margin-top: 0;
  }
  .navbar.navbar-scroll .navbar-brand{
  padding: 15px;
  margin-top: 0;
  }


.contact-field>img{
  width: 100%;
  height: 60px;
  border-radius: 5px;
  border:none;
}

#videoModal .modal-dialog .modal-body{
  color: #000000;
  font-family: 'Open Sans';
  font-size: 18px;
  height: auto;
  margin: 0;
  padding: 0;
 }


 #videoModal .modal-dialog{
  background-color:#ffffff;
  top: 100px;
  border-radius: 4px;
  box-shadow:0px 0px 0px 10px rgba(0,180,148,.2);
  width: 768px;
 }

 #videoModal .content iframe {
    border:none;
    padding:0;
    margin:0;
    margin-bottom: -6px;
    margin-top: 0px;
    height:450px; 
}

#videoModal button.close{
      margin-top: -35px;
      margin-right: -25px;
      color: white;
      font-size: 45px;
      width: 30px;
      height: 30px;
    }

/*Video Player*/
/***** MEDIA QUERIES *****/
 @media only screen and (max-width: 641px) {
    /***** MODAL PROPERTIES *****/
    #videoModal .modal-dialog .modal-body {
        padding:0;
        margin: 0;
    }
    #videoModal.modal-content {
        padding:0;
        margin: 0;
    }
    #videoModal .modal-dialog {
        position: relative;
        width: auto;
        height: auto;
        margin: 10px 25px 10px 10px;
    }

    #videoModal .content iframe {
    border:none;
    padding:0;
    margin:0;
    margin-bottom: -6px;
    margin-top: 0px;
    height: 300px;
    }

    #videoModal button.close{
      margin-top: -35px;
      margin-right: -10px;
    }
}

 @media only screen and (max-width: 321px) {
    #videoModal{
      width:83%;
    }

    #videoModal button.close{
      margin-top: -35px;
      margin-right: -15px;
    }
}

 @media only screen and (max-width: 400px) {
    #videoModal{
      width:100%;
    }

    #videoModal button.close{
      margin-top: -35px;
      margin-right: -15px;
    }
}
/*end of VIDEO PLAYER*/




/*UPGRADE FORM*/
.upgrade-field img{
  width: 100%;
  height: 60px;
  border-radius: 5px;
  border: none;
}

#upgrade_form .modal-dialog .content .modal-body .row{
  margin-left: -15px;
}
@media (min-width: 1200px){
  #upgrade_form .modal-dialog{
    width: 840px;
  }

  input[type="text"].upgrade-field{
    max-width:400px;
  }

  input[type="email"].upgrade-field{
    max-width:400px;
  }

  .promoType{
    margin-left: 28%;
    margin-right: 28%;
    width: 44%;
  }

  .upg-btn{
    margin-left: 7%;
    margin-right: 7%;
  }
}

@media (max-width: 1200px){
  #upgrade_form .modal-dialog{
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2%;
  }

  .promoType{
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
  }

  .upg-btn{
    margin-left: 5%;
    margin-right: 5%;
  }

  .fusionex-custom-featured-height{
    height:auto;
  }

  p.fusionex-caption-description{
    height: 15vh;
  }
}

/* start of max-width 450 */

@media screen and (max-width: 450px) {
  div.vertical-center{
    min-height: 5vh;
  }

  div.carousel-text{
    top: 10vh;
    left: 15%;
    width: 70%;
    text-align: center;
  }

  .fusionex-caption-title{
    font-size: 24px;
  }

  p.fusionex-caption-description{
    font-size: 16px;
    height: 20vh;
  }

  div .carousel-trynow{
    font-size:16px;
    font-weight: bold;
    margin-top: 0vh;
  }

  #navbar-trynow, #navbar-trynow div, .navbar-register{
    width:0px !important;
    height:0px !important;
    padding:0px !important;
    margin:0px !important;
  }

  .fusionex-carousel-content-title{
    font-size: 18px;
    padding: 0px;
    padding-top: 3%;
    height: 40%;
  }


  .fusionex-carousel-content-description{
    margin-top: 1%;
    font-size:14px;
  }

  p.overview-desc{
     padding: 0px;
  }     

  .fusionex-custom-featured-height{
    height:auto;

  }
}


/* end of max-width 400 */


a.fusionex-link{
  font-family: Open Sans;
  font-size: 18px;
  font-weight: bold;
  color: #d32827;
}

a:hover.fusionex-link{
  font-family: Open Sans;
  font-size: 18px;
  font-weight: bold;
  color: #d32827;
}

a:hover.trynow-btn{
  background-color:#006c59;
  border: 0;
}

a:hover.carousel-trynow{
  background-color:#006c59;
  border: 0;
}

@media (min-width: 400px){
    .navbar-toggle{
      margin-top: 19px;
      margin-bottom: 19px;
      margin-left: 19px;
    }
}

/*Change the max-width to larger if collapse come out earlier*/
/*Navbar styling for small device*/
@media (max-width: 1200px){
    .navbar-toggle{
      margin-top: 19px;
      margin-bottom: 19px;
      margin-left: 19px;
      margin-right: 5px;
    }

    .type2{
    position: absolute;
    width: 100vw;
    top: 80vh;
  }

    #navbar{
      background-color: black;
      margin-top: -1px; 
/*      padding-left: 10%;*/
      margin-left: -1px;
    }

    .navbar-wrapper .navbar {
      padding-left: 0;
    }

    .container>.navbar-collapse {
      margin-left: 0;
    }

}

.register-captcha-field{
  border-radius: 5px;
  border: none;
}

.fixed-nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  margin-right: 0;
}

#navbar-container {
  width: 100vw;
  padding: 0;
}

.home-right{
  position:absolute;
  left: 85vw;
}
.navbar-no-style{
  background-color: transparent;
  height: 80px;
  transition: all 0.05s ease-in 0s;
}
.navbar-add-style{
	background-color: white;
	height: 80px;
	transition: all 0.05s ease-in 0s;
}

.imageCol{
  height: 100%;
}

.customheight{
  height: 580px;
}



.container>.navbar-header{
/*	margin-left: -20%;
	margin-right: -20%;*/
  height: 80px;
}

.navbar-default .navbar-add-style .home-logo {/*
    background: url('../icons/ico-logo.png');*/
    background-repeat: no-repeat;
}

@media (min-width: 320px) and (max-width: 374px){

  .home-left{
    position:absolute;
    left: 5vw;
  }

  .home-right {
    position: absolute;
    left: 75vw;
  }

  .small-screen-form{
    padding-left: 8%;
    padding-right: 8%;
  }

}

@media (min-width: 375px) and (max-width: 479px){

  .home-left{
    position:absolute;
    left: 5vw;
    padding-top: 5px;
  }

  .home-right {
    position: absolute;
    left: 80vw;
    padding-top: 9px;
  }

  .small-screen-form{
    padding-left: 7%;
    padding-right: 7%;
  }

  .captcha{
    margin-left:12%;
    margin-right: 12%;
  }

  input[type="text"].textlimit{
  padding-bottom: 5px;
  margin-right: -20px;
  }

}

@media (min-width: 480px) and (max-width: 559px){

  .home-left{
    position:absolute;
    left: 5vw;
    padding-top: 5px;
  }

  .home-right {
    position: absolute;
    left: 80vw;
  }

  .small-screen-form{
    padding-left: 6.5%;
    padding-right: 6.5%;
  }

  .captcha{
    margin-left:15%;
    margin-right: 15%;
  }

}

@media (min-width: 560px) and (max-width: 767px){
  .home-left{
    position:absolute;
    left: 5vw;
  }
  
  .small-screen-form{
    padding-left: 5%;
    padding-right: 5%;
  }

  .captcha{
    padding-left:10%;
    padding-right: 10%;
  }
}


@media (min-width: 961px) and (max-width: 992px){

  .home-right {
    position: absolute;
    left: 85vw;
  }

}

@media (min-width: 320px) and (max-width: 374px){

  .captcha{
    padding-left:8%;
    padding-right: 8%;
  }

}

@media (min-width: 375px) and (max-width: 410px){
  .small-screen-form{
    padding-left: 8%;
    padding-right: 8%;
  }
}



@media (max-width: 768px){
  .navbar-container{
    width: 100%;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .container{
    width: 100%;
  }

  .pricing .col-sm-3{
    padding-left: 10px;
    padding-right: 10px;
  }

  input[type="text"].textlimit{
  padding-bottom: 20px !important;
  margin-right: -15px !important;
  }
}

@media only screen and( min-width: 640px) and (max-width: 768px){
  input[type="text"].textlimit{
  padding-bottom: 20px !important;
  margin-right: -3% !important;
  }
}


@media screen and (max-height: 450px){
  div.vertical-center{
    min-height: 5vh;
  }

  div.carousel-text{
    top: 13vh;
    left: 10%;
    width: 80%;
    text-align: center;
  }

  .fusionex-caption-title{
    font-size: 14px;
  }

  p.fusionex-caption-description{
    font-size: 13px;
    height: 12vh;
    margin-top: 10px !important;
  }

  div .carousel-trynow{
    font-size:13px;
    font-weight: bold;
    margin-top: 0vh;
  }

  #navbar-trynow, #navbar-trynow div, .navbar-register{
    width:0px !important;
    height:0px !important;
    padding:0px !important;
    margin:0px !important;
  }

  .fusionex-carousel-content-title{
    font-size: 14px;
    padding: 0px;
    padding-top: 3%;
    height: 40%;
  }

  .fusionex-carousel-content-description{
    font-size: 12px;
    padding: 0px;
    padding-top: 3%;
  }
}



@media only screen and (max-width: 1200px){
  #notif .modal-dialog{
    top: 20vh;
    width: 80%;
  }
}

.g-recaptcha{
  margin-left: auto;
  margin-right: auto;
}

.rc-anchor-light{
  background: black;
}


/*new added*/
.navbar-default.navbar-scroll {
    background-color: black;
    border-color: black;
/*    padding: 15px 0;*/
}

.navbar-wrapper .navbar.navbar-scroll {
     border-bottom: 1px solid black; 
}

.navbar-scroll.navbar-default .nav li a {
     color: #cbcbcb; 
}

.contact-main-top{
  background-color: #008dd5; 
  padding-top: 50px; 
  padding-bottom: 50px;
}
.contact-main-title h2{
  font-size: 32px;
  color: #ffffff;
  margin: 0;

}

.btn-primary, .btn-primary:hover{
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  background-color: #008dd5;
  border: 0;
}

h1.contact{
  color: #ffffff;
  margin-top: 60px;
}

h1{
  font-size: 34px;
  margin-top: 70px;
  margin-bottom: 30px;
  color: #008dd5;
}

h2{
  font-size: 36px;
  margin-top: 80px;
  margin-bottom: 30px;
  color: #008dd5;
}

textarea {
    resize: none;
    /*resize: vertical;*/
}

.gray-services{
  background-color: #f1f1f1;
  min-height: 580px;
}

.gray-background{ 
  position: absolute;
  width: 100%;
}

.service-2{
  background-image: url('../images/img-ourservice-2-back-image.png');
  min-height: 580px;
}

.our-services{
  min-height: 580px;
}

.our-services{
  min-height: 580px;
}

.our-services> .container> .services-background{
  height: 100vh;
  background-position: 45% 20%; 
  position: relative; 
  background-repeat: no-repeat;
  bottom: 0;
}

.our-services>.services-background>.container{
  height: 100vh;
}

p.smalltitle{
  font-size: 20px;
  font-weight: bold;
  color:#000000;
  margin-top: 10px;
}

h1.maintitle{
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 600;
}

p.desc{
  font-size:18px;
  margin-top: 20px;
  margin-bottom: 20px;
  color:#000000;
  line-height: 1.6;
}

.bubble1{
  position: absolute;
  top:45%;
  left:-5%;
}


.animated{
  -webkit-animation-duration: 2s; /* Chrome, Safari, Opera */
    animation-duration: 2s;
}

img.bubble1.animated{
    -webkit-animation-delay: 1s; /* Chrome, Safari, Opera */
    animation-delay: 1s;
  -webkit-animation-duration: 1s; /* Chrome, Safari, Opera */
    animation-duration: 1s;
}

img.bubble2.animated{
  -webkit-animation-delay: 2s; /* Chrome, Safari, Opera */
  animation-delay: 2s;
  -webkit-animation-duration: 1s; /* Chrome, Safari, Opera */
  animation-duration: 1s;
}

.bubble2{
  position: absolute;
  top:65%;
  left:25%;
}

.bubblebackground{
  position: absolute;
  top:1%;
  left:0%;
}


.left-right {
    overflow:hidden;
    height:600px;
    width:700px;
    position:relative;
    margin-left: -300px;
}
.slider {
    width:250px;
    height:400px;
    position:absolute;
    margin-left: -100px;
}

.footer{
  background-color: #1b242d;
  padding-top: 28px;
  padding-bottom: 28px;
  font-size: 14px;
  position: absolute;
  width: 100%;
}

.black-container{
  background-color: black;
}

.navbar-default .nav li a:hover{
  color: white;
}

a{
  color:#68c7f8;
}

a:hover{
  color:#68c7f8;
  text-decoration: none;
}

.no-color{
  color:black;
}

.background-cover1 {
    max-height: 70vh;
    min-height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.background-cover2 {
    max-height: 70vh;
    min-height: 580px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}



@media only screen and (min-width: 1200px){
  .3image-main{
    left:-3%;
    position: absolute;
  }

  .3image-sub1{
    left: -3%;
  }

  .3image-sub2>{
    left: -3%;
    top: 3%;
  }

  .captcha{
  padding-left:22%;
  padding-right: 22%;
  }

  input[type="text"].textlimit{
    padding-bottom: 20px;
    margin-right: -15px;
  }

  .bubble1{
  position: absolute;
  top:40%;
  left:-12%;
  }
  .bubble2{
    position: absolute;
    top:65%;
    left:25%;
  }
}

@media only screen and (min-width: 992px){
  .3image-main img{
    margin-left: auto;
    margin-right: auto;
  }

  .3image-sub1{
  }

  .3image-sub2{
  }
}

@media only screen and (max-width: 1200px){
  .3image-main{
    left:50%;
    position: absolute;
  }

  .3image-sub1{
    left: 1%;
    position: absolute;
  }

  .3image-sub2{
    left: 1%;
    position: absolute;
    top: 10%;
  }

  .bubble1{
  position: absolute;
  top:40%;
  left:-20%;
  }
  .bubble2{
    position: absolute;
    top:65%;
    left:15%;
  }

}

.caption{
  height:100%;
  position: relative;
}
.caption-position{/*
  height: 100%;
  position: absolute;*/
  top: 12%;
}
/*.custom-height{
  height: 100%;  
}*/

.caption>.caption-position> h1{
  font-size:54px;
  color: #ffffff;
  border-bottom: 30px;
/*  text-shadow: 10px 2px #4c4c4c;*/
}

.caption>.caption-position> p{
  color: #ffffff;
  font-size: 18px;
  line-height: 30px;
}
/*.carousel {
    height: 70vh;
}*/

/*.carousel-inner>.item>img{
  height:70vh;
  max-height: 693px;
}*/

/*.carousel-inner>.item{
  height: 100vh;
  max-height: 693px;
}*/

.carousel-inner>.item>.container{
  height: 100%;
  max-height: 693px;
}

.carousel-inner>.item>.container>.row{
  height: 100%;
  max-height: 693px;
}

.ibox{
  max-height: 693px;
  position: relative;
}

.carousel-control {
position: absolute;
top: 50%; /* pushes the icon in the middle of the height */
z-index: 5;
display: inline-block;
}

/*.carousel{
  height:693px;
}*/

/*.carousel .item{
  height: 70vh;
}*/

.carousel-animation{
  position: relative;
  bottom: 0;
/*  width: 320px;
  height: 420px; */
}

.carousel-motion-image{
/*  width: 320px;
  height: 420px;*/
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%; 
 }

.contact-dropdown{
  background-image:url('../icons/ico-dropdown-arrow.png');
  right:0;
  background-repeat: no-repeat; 
  background-color: white;
  font-size: 14px;
}


.form-control{
  position: relative;
}

.has-error .form-error-message{
  display:inline;
}

.form-error-message{
  background-image:url('../icons/ico-error-icon.png');
  background-repeat: no-repeat;
  position: absolute;
  right:20px;
  top: 12%;
  width: 26px;
  height: 26px;
  display: none;
}

#title.form-error-message{
  right:36px;
}

#country.form-error-message{
  right: 30px;
}

#subject.form-error-message{
  right: 30px;
}

p.contact{
  font-size: 16px;
  color: #ffffff;
}


.red-tooltip + .tooltip > .tooltip-inner {background-color: red; width:150px;}
.red-tooltip + .tooltip > .tooltip-arrow { border-top: 5px solid red; }

#contact-form > .animated{
  -webkit-animation-delay: 1s; /* Chrome, Safari, Opera */
  animation-delay: 1s;
  -webkit-animation-duration: 1s; /* Chrome, Safari, Opera */
  animation-duration: 1s;
}

.page-padding{
  padding-top: 30px;
  padding-bottom: 80px;
}


.page-scroll{
  margin-left: 35px;
  margin-right: 35px;
}

.navbar-default .nav li a{
  font-weight:600;
  font-size: 17px;
}


.header{
  font-weight:600;
}

.form-control.m-b-md{
  margin-bottom: 15px;
}

/*.services-2 >.our-services > .row > .col-md-12{
 padding:0;
 position:relative;
}*/



.services-2 > .row >.our-services{
   background-position: 45% 20%;
   position: relative;
   background-repeat: no-repeat;
}

.services-2 > .row >.our-services > .imageCol> .service2-image{
  margin-top: 90px;
  padding-top: 90px;
  bottom:0;
}

.services-2 > .row >.our-services > .textbox{
  padding-top: 90px;
  padding-left: 60px;
}

.services-2 > .row >.our-services > .textbox > p.desc{
  padding-right: 25px;
}

.services-3 >.container > .row > .our-services{
  padding-top: 90px;
}

.services-3 >.container > .row > .our-services >.imageCol{
  margin-top:-3.5%;
}

.services-3 >.container > .row > .our-services > .col-lg-6 > p.desc.desc1{
  padding-right: 25px;
}

.services-3 >.container > .row > .our-services > .col-lg-6 > p.desc.desc2{
  padding-right: 35px;
}

.desc-margin{
  margin-bottom:45px;
}

.sub-desc{
  margin-bottom:5px;
}

.aboutus{
  overflow: hidden;
}

.aboutus > .row > .textbox > h1.subtitle{
  margin-bottom:25px;
  font-size: 32px;
  margin-top: 0;
  color:#000000;
}

.aboutus > .row > .imageCol > .imageBox >img.margin-top-40{
  margin-top: 40px;
}

.aboutus > .row > .imageCol > .imageBox > .desc{
  font-size: 20px;
  padding-top: 10px;
}

/*navbar-new adjustment*/
#navbar-container.container{
  height:100px;
}

.navbar-nav>li>a{
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav>li>a.page-scroll{
  padding-left: 0;
  padding-right: 0;
  padding-top: 38px;
}


/*ending of navbar adjustment*/

.page-style{
   padding-top: 100px; 
   min-height:95vh;
}

.page-style > .page-padding{
  line-height: 1.6;
}

.send-button{
  padding-top: 25px;
  padding-bottom: 40px;
}

.contact-section > .container{
  padding-bottom: 2%;
}

.btn-send{
  width: 100%;
}

/*iphone 6*/
@media (min-width: 361px) and (max-width: 375px){
  .captcha{
    padding-left:0;
    margin-left: 10%;
    margin-right: 10%;
  }

  input[type="text"].textlimit{
  padding-bottom: 5px;
  margin-right: -20px;
  }

  .paddingtop90{
    padding-top: 45px;
  }

  .our-services{
    background-position: 20% center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .desktop-show{
    display:none;
  }
}

/*iphone 5*/
@media only screen and (max-width: 320px){
  .captcha{
    padding-left:3%;
  }

  .small-screen-form {
    padding-left: 9%;
    padding-right: 9%;
  }

  input[type="text"].textlimit{
    padding-bottom: 5px;
    margin-right: -20px;
  }
}

@media (min-width: 668px) and (max-width: 736px){
  .small-screen-form {
    padding-left: 4%;
    padding-right: 4%;
  }

    input[type="text"].textlimit{
    padding-bottom: 5px;
    margin-right: -20px;
  }
}


#recaptcha.form-error-message{
  left:60%;
}

span.textlimit-box{
  position: absolute;
  bottom: 0;
  right:0; 
}

input[type="text"].textlimit{
  background:transparent !important;
  border:none !important;
  color:#a3a3a3 !important;
  opacity: 0.7 !important;
}

/*remove navbar text go down*/

.navbar-wrapper .navbar.navbar-scroll {
  padding-bottom: 0;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border : none;
    box-shadow: none;
}

@media only screen and (min-width: 768px){
  .navbar{
  border: 0 !important;
  }

  .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-left: 2%;
  }
}


@media only screen and (min-width: 1200px){
  .navbar-scroll .navbar-nav>li>a{
    padding-top: 38px;
    padding-bottom: 15px;
  }

  .navbar.navbar-scroll .navbar-brand{
  margin-top: 0px;
  }
}
/*end*/

/*image-resize*/
/*img.image-resize1{
  height: 450px;
  width: 450px;
}

img.image-resize2{
  width:450px;
  height:90px;
}*/

.image-resize4{
  display:block;
  margin-left: auto;
  margin-right: auto;
}

/*img.logo-resize{
  width:90%;
  height:90%;
  margin-top: 1%;
}*/
@media only screen and (min-width: 1400px){
  .carousel-inner>.item>img{
  height:100vh;
  max-height: 693px;
  }

  .carousel .item{
  height: 100vh;
  }

  .carousel-inner>.item{
  height: 100vh;
  max-height: 693px;
  }

  .ibox{
    height:100vh;
    max-height: 693px;
  }


.carousel {
    height: 100vh;
    max-height: 693px;
  }

  p.contact{
    font-size: 14px;
    margin-bottom:30px;
  }
}

@media only screen and (max-width: 1400px){
  img.carousel-animation-resize{
    width: 20vw;
}
  .caption-position>h1{
    font-size: 44px !important;
  }
}

.carousel-overflow{
  overflow:hidden;
}

.carousel-animation > .animated{
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.servicesbox50{
padding-right: 50px;
overflow:hidden;
}

.servicesbox40{
padding-right: 40px;
}

.servicesbox70{
padding-right: 70px;
}

.services-imagebox{
  width: 540px;
  height: 450px;
  left:10%;
}

/*laptop view*/
@media only screen and (max-width: 1400px){
.navbar-default .nav li a{
    font-size: 14px;
}

input[type="text"],input[type="email"]{
  font-size: 14px;
}

textarea.font{
  font-size: 14px;
}

input[type="password"].contact-field, input[type="text"].contact-field{
  height: 32px;
  border-radius: 3px;
}

.contact-field{
  height: 32px;
}

p.subtitle{
  font-size: 16px;
}

p.description{
  font-size: 16px;
}

.navbar{
  min-height: 80px;
  margin-bottom: 0;
}

.navbar-toggle{
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 4px;
}

.contact-main-title h2{
  font-size: 25px;

}

.btn-primary, .btn-primary:hover{
  font-size: 14px;
}

h1{
  font-size: 34px;
}

h2{
  font-size: 36px;
}


p.smalltitle{
  font-size: 16px;
  margin-top: 8px;
}

h1.maintitle{
  margin-top: 16px;
  margin-bottom: 16px;
}

p.desc{
  font-size:18px;
}

p.subdesc{
  font-size:18px;
}

.footer{
  font-size: 14px;
}

.aboutus > .row > .textbox > h1.subtitle{
  font-size: 25px;
}
.aboutus > .row > .imageCol > .imageBox > .desc{
  font-size: 16px;
}

#navbar-container.container{
  height:80px;
}

.navbar-nav>li>a{
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav>li>a.page-scroll{
  padding-left: 0;
  padding-right: 0;
  padding-top: 30px;
}

h1{
  font-size: 27px;
}

p.contact{
  font-size: 18px;
  margin-bottom:30px;
}

p.contact-required{
  font-size: 16px;
  margin-bottom:15px;
}

.image-on-right{
    margin-left: -20%;
  }

.service2-image {
    width: 50%;
    height: auto;
}


/*  .services-2 > .row >.our-services{
    background-position: 40% center;
  }*/
}



@media only screen and (min-width: 1200px){
  .services-imagebox {
    left: -6%;
  }

  .desktop-carousel{
  padding-top:50px;
  }

}

.absolute-bottom{
  position: absolute;
  bottom:0;
}

.position-relative{
  position: relative;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus{
  color: #cbcbcb;
}

@media (min-width: 1400px){
    .image-on-right{
    margin-left: -35%;
  }

  .desktop-carousel{
  padding-top:110px;
  }
  
}

@media (min-width: 1025px) and (max-width: 1200px){
  .services-imagebox {
    left: -4%;
  }
}

@media (max-width: 1024px){
  .bubblebackground{
    width: 80%;
    height:auto;
  }

  .bubble1{
  position: absolute;
  top:45%;
  left:-25%;
  width: 80%;
  height: auto;
  }
  
  .bubble2{
    position: absolute;
    top:65%;
    left:2%; 
    width: 80%;
    height: auto;
  }


}

@media only screen and (max-width: 1023px){
  .image-resize4{
    width: 60vw;
    height: 70vh;
  }

  .sm-image-1{
    width: 90%;
    height: 90%;
  }

  .aboutus > .container >.row > .imageCol > .imageBox > .desc{
    font-size: 16px;
    height: 50px;
  }

  p.subdesc {
    font-size: 18px;
  }

  .aboutus > .row > .textbox > h1.subtitle{
    font-size: 20px;
  }

  h1{
    font-size: 22px;
  }

  p.smalltitle {
    font-size: 16px;
  }

  .navy-line{
    width: 60px;
  }

  p.desc {
    font-size: 18px;
  }

  .image-on-right {
    margin-left: 0;
  }

  .services3-custom-height{
    min-height: 460px !important;
  }

  .contact-main-title h2{
    font-size: 20px;
  }

  .contact-main-top {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  p.contact {
    font-size: 18px;
  }

  p.contact-required {
    font-size: 16px;
  }

  .margin-bottom-20{
    margin-bottom: 20px;
  }

  .service2-image{
    width:30vw;
    height: auto;
    left:15vw;
  }
  
  .our-services{
    min-height: 500px;
  }

  .customheight{
    height: 500px;
  }

}

@media (min-width: 768px) and (max-width: 992px){
  .image-resize4 {
    width: 60vw;
    height: auto;
  }
  .image-on-right {
    margin-left: auto;
    margin-right: auto;
  }


  .services-2 > .row >.our-services{
    background-position: 25% 35%;
  }

  .services-imagebox {
    left: 14%;
  }

  .navbar-brand{
    margin-top: 0;
    padding: 10px;
  }

  .navbar.navbar-scroll .navbar-brand {
    padding: 15px;
  }
}

@media only screen and (max-width: 767px){
  .image-resize4{
    width: 60vw;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .sm-image-1{
    width: 70vw;
    height: auto;
  }

  .services-2 > .row >.our-services > .textbox{
    padding-top: 45px;
    padding-left: 15px;
  }

  .services-3 >.container > .row > .our-services {
    padding-top: 45px;
  }

  .gray-services {
    min-height: 460px;
  }

  img.image-resize1 {
    width: 70vw;
    height: auto;
  }

  .bubble1 {
    position: absolute;
    top: 40%;
    left: -10%;
  }

  .bubble2 {
    position: absolute;
    top: 65%;
    left: 16%;
  }

  .services-imagebox {
    left: 12%;
  }

  .copyright{
    left:0;
    text-align: left;
    margin-top: 20px;
    color: #a3a3a3;
  }

  .bubblebackground {
    left: 0%;
  }
}



@media (min-width: 768px){

  .form-padding{
    padding-left: 30px;
    padding-right: 30px;
  }

  .register-button{
        /*width: 280px;*/
        width: 240px;
  }

  .contact-section {
    min-height:662px;
  }
  .form-padding{
    padding-left: 30px;
    padding-right: 30px;
  }

  .register-button{
        /*width: 280px;*/
        width: 240px;
  }

  .contact-section {
    min-height:662px;
  }
  .copyright{
    right:0 !important;
    text-align: right !important;
    color: #a3a3a3
  }
}

@media (min-width: 640px) and (max-width: 750px){
  .sm-image-1{
    width: 50% !important;
    height: auto !important;
  }

  img.image-resize1{
    width: 50%;
    height: auto;
  }

  .servicesbox40 {
     padding-right: 0; 
  }

  .servicesbox70 {
    padding-right: 0;
  }
  .services-2 > .row >.our-services {
    background-size: cover;
    background-position: 30% center;
  }
}

@media only screen and (max-width: 667px){
  .image-resize4{
    width: 80vw;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  img.image-resize2 {
    width: 60vw;
    height: auto;
  }


}

@media (max-width: 640px){
  .bubble1{
    top: 38%;
  }

  .bubble2{
    left: 22%;
  }
}

@media only screen and (max-width: 568px){
  .sm-image-1{
    width: 50%;
    height: auto;
  }

/*  img.logo-resize {
    width: 60%;
    height: 80%;
    margin-top: 0%;
  }*/
}

@media only screen and (max-width: 440px){
  .sm-image-1{
    width: 70%;
    height: auto;
  }

  .aboutus > .container >.row > .imageCol > .imageBox > .desc{
    font-size: 16px;
    height: 60px;
  }

  img.image-resize2 {
    width: 74%;
    height: auto;
  }

/*  .services-imagebox {
    width: 100%;
    height: auto;
    left: 2%;
  }*/

  .bubble1 {
     left: -10vw; 
  }

  .services-2 > .row >.services-background {
    background-size: cover;
    background-position: 30% center;
  }

  .our-services{
    min-height: 400px;
  }

  .customheight{
    height: 400px;
  }
}

@media only screen and (max-width: 375px){
  .services-imagebox {
    top: 0;
    left: 2%;
  }
  .services-2 > .row >.services-background {
    background-size: cover;
    background-position: 30% center;
  }

/*  img.logo-resize {
    width: 50%;
    height: 80%;
    margin-top: 0%;
  }*/
  .bubblebackground {
    left: 0%;
  }

  .bubble1{
    top:44%;
    left: -1%;
  }

  .bubble2 {
    left: 23%;
  }

  img.image-resize1 {
    width: 70vw;
    height: auto;
  }

}

@media only screen and (max-width: 320px){
  .bubblebackground {
      left: 0%;
  }
}

@media only screen and (max-width: 1200px){

  .caption-position>h1 {
    font-size: 26px !important;
    line-height: 1.2;
  }

  .caption >.caption-position> p {
    font-size: 14px;
  }

  .navbar-scroll > .container > .navbar-header > .navbar-brand > .home-logo{
  margin-top: 0;
  }
}

.custom-position{
  height: 100%;
}

.image-position{
  bottom: 70vh;
}
@media (max-width: 992px){
  .desktop-show{
    display:none;
  }

  h1{
    font-size: 20px;
  }

  .servicesbox40 {
    padding-right: 0;
  }

  .servicesbox50 {
    padding-right: 15px;
    overflow: hidden;
  }

  .servicesbox70 {
    padding-right: 0;
  }

  .services-3 >.container > .row > .our-services > .col-lg-6 > p.desc.desc1 {
    padding-right: 0;
  }

  .services-3 >.container > .row > .our-services > .col-lg-6 > p.desc.desc1 {
    padding-right: 0;
  }

  .margin-top-bottom-20{
    margin-top: 10px;
    margin-bottom: 10px;
  }

  img.image-resize1{
  display:block;
  margin-left: auto;
  margin-right: auto;  
  }

  .services-3 >.container > .row > .our-services{
    padding-top: 45px;
  }


  .custom-height{
    height:45vh;
  }

  .services-2 > .row >.services-background {
    background-size: cover;
    background-position: 30% center;
  }

}
@media (min-width: 992px){
  .mobile-show{
    display:none;
  }
}

.mobile-carousel-text{
  background-color: rgb(107, 107, 107)
}

.navbar-default .navbar-toggle .icon-bar{
  background-color:#AA9F6D;
  width: 25px;
  height: 3px;
}

.carousel {
     height: 100%; 
}

@media (max-width: 773px){
  .background-cover1 {
    background-position: 80% center;
  }

  .background-cover2 {
    min-height: 45vh;
    background-position: 15% center;
  }

  img.carousel-animation-resize {
    width: 20vw;
    height: auto;
    left: 50%;
  }
}

@media (max-width: 960px){
  .background-cover1 {
    background-position: 80% center;
  }

  .background-cover2 {
    background-position: 10% center;
  }

  img.carousel-animation-resize {
    width: 20%;
    height: auto;
    left: 47%;
  }
}

@media (max-width: 768px){
  .background-cover1 {
    background-position: 80% center;
  }
  .background-cover2 {
    background-position: 15% center;
  }

  img.carousel-animation-resize {
    width: 30vw;
    height: auto;
    left: 30%;
  }

  .services-2 > .row >.services-background {
    background-size: cover;
    background-position: 22% center;
  }

  .imageCol{
    position: relative;
  }

  .bubblebackground {
    width: 50%;
    height: auto;
  }
  .bubble1{
  position: absolute;
  top:50%;
  left:-5%;
  }
  .bubble2{
    position: absolute;
    top:70%;
    left:25%;
  }

}

@media (max-width: 736px){
  .background-cover1 {
    background-position: 80% center;
  }

  .background-cover2 {
    background-position: 10% center;
  }

  img.carousel-animation-resize {
    width: 20vw;
    height: auto;
    left: 50%;
  }

  .services-2 > .row >.services-background {
    background-size: cover;
    background-position: 22% center;
  }

    .bubblebackground {
    left: 0%;
    width: 50%;
    height:auto;
  }

  .bubble1{
  position: absolute;
  top:50%;
  left:5%;
  width: 70%;
  height: auto;
  }
  
  .bubble2{
    position: absolute;
    top:70%;
    left:30%; 
    width: 70%;
    height: auto;
  }
}

@media (max-width: 667px){
  
  .bubblebackground {
    left: 0%;
    width: 50%;
    height:auto;
  }

  .bubble1{
  position: absolute;
  top:55%;
  left:-5vw;
  width: 60vw;
  height: auto;
  }
  
  .bubble2{
    position: absolute;
    top:75%;
    left:20vw; 
    width: 60vw;
    height: auto;
  }
  .service2-image {
    width: 35vw;
    height: auto;
    left: 10vw;
  }

  .services-2 > .row >.services-background {
    background-size: cover;
    background-position: 25% center;
  }
}

@media only screen and (max-width: 600px){
  img.carousel-animation-resize {
    width: 20vw;
    height: auto;
    left: 50%;
  }

  .services-2 > .row >.services-background {
    background-size: cover;
    background-position: 30% center;
  }

@media (max-width: 435px){
  .background-cover1 {
    background-position: 45% center;
  }

  .background-cover2 {
    background-position: 45% center;
  }

  img.carousel-animation-resize {
    width: 50%;
    height: auto;
    left: 25%;
  }
}


@media (max-width: 414px){
  img.carousel-animation-resize {
    width: 60%;
    height: auto;
    left: 20%;
  }

  .background-cover1 {
    background-position: 42% center;
  }

  .background-cover2 {
    min-height: 50vh;
    background-position: 24% center;
  }
  .services-2{
    min-height: 50vh;
    position: relative;
  }

  .service2-image {
    width: 45vw;
    height: auto;
    left: 0;
  }

/*  img.image-resize2 {
    width: 70%;
    height: auto;
  }*/

  img.bubble1{
  position: absolute;
  top:20%;
  left:0;
  width: 70vw;
  height: auto;
  }
  
  img.bubble2{
    position: absolute;
    top:30%;
    left:27vw; 
    width: 70vw;
    height: auto;
  }

  .services-2 > .row >.services-background {
    background-size: cover;
    background-position: 30% center;
  }

/*  .navbar-toggle{
    padding-top: 0;
  }*/
}

@media (max-width: 375px){
    img.bubble1{
  position: absolute;
  top:24%;
  left:0;
  width: 70vw;
  height: auto;
  }
  
  img.bubble2{
    position: absolute;
    top:37%;
    left:27vw; 
    width: 70vw;
    height: auto;
  }
}

@media (max-width: 320px){
  .services-2 > .row >.services-background {
    background-size: cover;
    background-position: 25% center;
  }

  .image-resize4 {
    width: 80vw;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .service2-image {
    width: 50vw;
    height: auto;
    left: 0;
  }

}


  .services-2 > .row >.services-background {
    background-size: cover;
    background-position: 30% center;
  }

.desc-height{
  height: 20px;
}

















